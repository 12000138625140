import React, { useEffect } from "react";
import { PageSection } from "../../styles/index/_components";
import Layout, { PageWrapper } from "../../components/layout";
import Human from "../../components/people/Human";
import { StaticImage } from "gatsby-plugin-image";
import "twin.macro";
import { cleanUpZnanyLekarz, loadZnanyLekarz } from "../../utils/utils";
import Enumeration from "../../components/text/enumeration";

const JustynaText = {
  name: "Justyna",
  surname: "Barton",
  phone: ["507", "164", "604"],
  desc1:
    "Jestem psycholożką, psychoseksuolożką oraz psychotraumatolożką w trakcie certyfikacji, przyjmującą w Gdańsku. Ukończyłam pięcioletnie psychologiczne studia magisterskie oraz studia pierwszego stopnia na kierunku psychoseksuologia, na Uniwersytecie Humanistycznospołecznym SWPS w Sopocie. Dodatkowo ukończyłam przygotowanie pedagogiczne dla psychologów.\n",
  desc2:
    "Swoje kompetencje zwiększam poprzez udział w kursach i konferencjach również międzynarodowych z zakresu psychologii i seksuologii. Dotyczą one min. takich tematów jak seksuologia sądowa, wykorzystanie seksualne dziecka, seksualność w XXI wieku, płodność i zdrowie psychoseksualne pary, jakość życia w niepłodności. Szczególnie zajmuje mnie praca w obszarze edukacji seksualnej dzieci, młodzieży oraz osób dorosłych, przeżycia traumatyczne oraz nadużycia seksualne.\n",
  desc3:
    "Jako psychoseksuolożka oraz wolontariuszka opiekuńcza i akcyjna współpracuję z Hospicjum im. Ks. Eugeniusza Dutkiewicza w Gdańsku. Moje doświadczenie zawodowe podparte jest także praktyką zawodową odbywaną w Szpitalu dla Nerwowo i Psychicznie Chorych im. Stanisława Kryzana w Starogardzie Gdańskim oraz w Poradni Seksuologicznej i psychoterapii INTIMED – Zakładu Seksuologii Klinicznej w Gdyni.\n",
};

const Justyna = () => {
  useEffect(() => {
    cleanUpZnanyLekarz(document, "zl-widget-s");
    loadZnanyLekarz(document, "script", "zl-widget-s");
  });

  return (
    <Layout>
      <PageSection>
        <PageWrapper>
          <div tw="grid grid-flow-row md:grid-flow-col md:grid-cols-2 items-start">
            <StaticImage
              alt={"human picture"}
              src={"../../images/people/justyna.jpg"}
              placeholder="blurred"
              layout="fullWidth"
            />
            <Human human={JustynaText} />
          </div>
          <div>
            <Enumeration
              title={"Pomoc psychologiczna w zakresie:"}
              items={[
                "Ustalanie celów życiowych, granic i potrzeb",
                "Sytuacje kryzysowe (utrata pracy, choroby, śmierć bliskiej osoby)",
                "Podnoszenie samooceny",
                "Zrozumienie i kontrolowanie emocji",
              ]}
            />
            <Enumeration
              title={"Pomoc seksuologiczna w zakresie:"}
              items={[
                "Edukacja psychoseksualna dzieci i młodzieży oraz osób dorosłych",
                "Pomoc w określeniu normy rozwojowej dzieci i młodzieży",
                "Wsparcie osób o różnych orientacjach i tożsamościach płciowych oraz ich rodzin",
                "Wsparcie pacjenta oraz jego rodziny w procesie tranzycji",
                "Praca z osobami po wykorzystaniu seksualnym",
              ]}
            />
          </div>
          <div>
            <a
              id="zl-url"
              className="zl-url"
              href="https://www.znanylekarz.pl/justyna-barton/psycholog-seksuolog/gdansk"
              rel="nofollow"
              data-zlw-doctor="justyna-barton"
              data-zlw-type="big_with_calendar"
              data-zlw-opinion="false"
              data-zlw-hide-branding="true"
            >
              Justyna Barton - ZnanyLekarz.pl
            </a>
          </div>
        </PageWrapper>
      </PageSection>
    </Layout>
  );
};

export default Justyna;
